import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import TagManager from 'react-gtm-module'
import GodrejAthena from "../../../components/developers/godrejdeveloper/ourproperties/GodrejAthena";

const favicon_logo = require("../../../images/favicon.png");

const GodrejAthenaPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <GodrejAthena />
    </>
  )
}

export default GodrejAthenaPage
